exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "/*\n * Styles for the Row component used by formsy-react-components\n * Should be imported for each component that extends one of\n * the formsy-react-components\n */\n\n.row_row_3lc3e {\n    .row_required-symbol_3NUZd {\n        display: none;\n    }\n\n    label {\n        display: inline-block;\n        margin-bottom: .75rem;\n    }\n\n    &.row_no-label_1QIcp {\n        label {\n            display: none;\n        }\n    }\n}\n\n.row_row-label_3N90U {\n    margin-bottom: .75rem;\n    line-height: 1.7rem;\n}\n", ""]);

// exports
exports.locals = {
	"row": "row_row_3lc3e",
	"required-symbol": "row_required-symbol_3NUZd",
	"requiredSymbol": "row_required-symbol_3NUZd",
	"no-label": "row_no-label_1QIcp",
	"noLabel": "row_no-label_1QIcp",
	"row-label": "row_row-label_3N90U",
	"rowLabel": "row_row-label_3N90U"
};
exports = module.exports = require("../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "html,\nbody,\n.index_app_3Qs6X {\n    /* probably unecessary, transitional until layout is refactored */\n    width: 100%; \n    height: 100%;\n    margin: 0;\n\n    /* Setting min height/width makes the UI scroll below those sizes */\n    min-width: 1024px;\n    min-height: 640px; /* Min height to fit sprite/backdrop button */\n}\n@media (max-width: 992px) { /* lch1721 step 1 fit screen */\n\thtml, body, .index_app_3Qs6X {\n\t\tmin-width: 0px;\n    \tmin-height: 0px; /* Min height to fit sprite/backdrop button */\n\t}\t\n}\n/* @todo: move globally? Safe / side FX, for blocks particularly? */\n* { -webkit-box-sizing: border-box; box-sizing: border-box; }\n\n", ""]);

// exports
exports.locals = {
	"app": "index_app_3Qs6X"
};
import decks from '../lib/libraries/decks/faceapi.jsx';

const CLOSE_CARDS = 'scratch-gui/teachablemachine/CLOSE_CARDS';
const VIEW_CARDS = 'scratch-gui/teachablemachine/VIEW_CARDS';
const ACTIVATE_DECK = 'scratch-gui/teachablemachine/ACTIVATE_DECK';
const NEXT_STEP = 'scratch-gui/teachablemachine/NEXT_STEP';
const PREV_STEP = 'scratch-gui/teachablemachine/PREV_STEP';
const DRAG_CARD = 'scratch-gui/teachablemachine/DRAG_CARD';
const START_DRAG = 'scratch-gui/teachablemachine/START_DRAG';
const END_DRAG = 'scratch-gui/teachablemachine/END_DRAG';
const SET_CONTENT = 'scratch-gui/teachablemachine/SET_CONTENT';

const initialState = {
    visible: false,
    content: decks,
    activeDeckId: 'video1',
    step: 0,
    x: 0,
    y: 0,
    dragging: false
};

const reducer = function (state, action) {
    if (typeof state === 'undefined') state = initialState;
    switch (action.type) {
    case CLOSE_CARDS:
        return Object.assign({}, state, {
            visible: false
        });
    case VIEW_CARDS:
        return Object.assign({}, state, {
            visible: true
        });
    case ACTIVATE_DECK:
        return Object.assign({}, state, {
            activeDeckId: action.activeDeckId,
            step: 0,
            visible: true
        });
    case NEXT_STEP:
        if (state.activeDeckId !== null) {
            return Object.assign({}, state, {
                step: state.step + 1
            });
        }
        return state;
    case PREV_STEP:
        if (state.activeDeckId !== null) {
            if (state.step > 0) {
                return Object.assign({}, state, {
                    step: state.step - 1
                });
            }
        }
        return state;
    case DRAG_CARD:
        return Object.assign({}, state, {
            x: action.x,
            y: action.y
        });
    case START_DRAG:
        return Object.assign({}, state, {
            dragging: true
        });
    case END_DRAG:
        return Object.assign({}, state, {
            dragging: false
        });
    case SET_CONTENT:
        return Object.assign({}, state, {
            content: action.content
        });
    default:
        return state;
    }
};

const activateTeachableMachineDeck = function (activeDeckId) {
    return {
        type: ACTIVATE_DECK,
        activeDeckId
    };
};

const viewTeachableMachine = function () {
    return {type: VIEW_CARDS};
};

const closeTeachableMachine = function () {
    return {type: CLOSE_CARDS};
};

const nextTeachableMachineStep = function () {
    return {type: NEXT_STEP};
};

const prevTeachableMachineStep = function () {
    return {type: PREV_STEP};
};

const dragTeachableMachine = function (x, y) {
    return {type: DRAG_CARD, x, y};
};

const startTeachableMachineDrag = function () {
    return {type: START_DRAG};
};

const endTeachableMachineDrag = function () {
    return {type: END_DRAG};
};
const setTeachableMachineContent = function (content) {
    return {
        type: SET_CONTENT,
        content
    };
};

export {
    reducer as default,
    initialState as teachableMachineInitialState,
    activateTeachableMachineDeck,
    viewTeachableMachine,
    closeTeachableMachine,
    nextTeachableMachineStep,
    prevTeachableMachineStep,
    dragTeachableMachine,
    startTeachableMachineDrag,
    endTeachableMachineDrag,
    setTeachableMachineContent
};

const bindAll = require('lodash.bindall');
const FormattedMessage = require('react-intl').FormattedMessage;
const PropTypes = require('prop-types');
const React = require('react');

const Form = require('../forms/form.jsx');
const Input = require('../forms/input.jsx');
const Button = require('../forms/button.jsx');
const Spinner = require('./spinner.jsx');
const FlexRow = require('../flex-row/flex-row.jsx');

const classNames = require('classnames');

const styles = require('./login.scss');

const axios = require('axios');

const sessionActions = require('../../reducers/session.js');

class Login extends React.Component {
    constructor (props) {
        super(props);
        bindAll(this, [
            'handleSubmit',
            'handleLoadingOver'
        ]);
        this.state = {
            waiting: false
        };
    }
    componentDidMount(){
        document.handleLoadingOver = this.handleLoadingOver;
        
    }
    handleLoadingOver(data){
        this.setState({waiting: false});
        
        document.currentUser = data;
        //sessionActions.refreshSession3();
        if(data){
            this.props.onLoggedIn(data.uid,data.username);
        }
        
        this.props.onClose();
    }
    handleSubmit (event) {
        event.preventDefault();
        
        const data = new FormData(event.target);
        // data.username = "lch1721@gmail.com";
        // data.password = "rhaehfdl";
        var username = "";
        var password = "";
        try {
            username = event.target.elements["username"];
            password = event.target.elements["password"];
        } catch (error) {
            
        }
        if(username === ""){
            
           
        } else {
            
        }
        this.setState({waiting: true});

        axios({
            method: 'post',
            url: 'https://www.commoncoding.io/api/v1/login',
            data: data,
            headers: {'Content-Type': 'application/x-www-form-urlencoded' }
            })
            .then(function (response) {
                //handle success
                
                if(response && response.data){
                    document.handleLoadingOver(response.data);
                } else {
                    document.handleLoadingOver(null);
                }
                
            })
            .catch(function (response) {
                //handle error
                
            });
        // this.props.onLogIn(data,()=>{
        //     this.setState({waiting: false});
        // });
    }
    
    render () {
        let error;
        if (this.props.error) {
            error = <div className="error">{this.props.error}</div>;
        }
        return (
            <div className="login">
                <form onSubmit={this.handleSubmit}>
                    <label
                        htmlFor="username"
                        key="usernameLabel"
                    >
                        {/* <FormattedMessage id="general.username" /> */}
                        Email
                    </label>
                    <br />
                    <input
                        required
                        key="usernameInput"
                        maxLength="30"
                        name="username"
                        type="text"
                    />
                    <br />
                    <label
                        htmlFor="password"
                        key="passwordLabel"
                    >
                        {/* <FormattedMessage id="general.password" /> */}
                        Password
                    </label>
                    <br />
                    <input
                        required
                        key="passwordInput"
                        name="password"
                        type="password"
                    />
                    <br />
                     <FlexRow className="submit-row">
                        {this.state.waiting ? [
                            <Button
                                className="submit-button white"
                                disabled="disabled"
                                key="submitButton"
                                type="submit"
                            >
                                <Spinner
                                    className="spinner"
                                    color="blue"
                                />
                            </Button>
                        ] : [
                            <Button
                                className="submit-button white"
                                key="submitButton"
                                type="submit"
                            >
                                <FormattedMessage id="general.signIn" />
                            </Button>
                        ]}
                        
                        
                        <a
                            href="/login"
                            key="passwordResetLink"
                        >
                            <FormattedMessage id="login.needHelp" />
                        </a>
                     </FlexRow>
                    {error} 
                </form>
            </div>
        );
    }
}

Login.propTypes = {
    error: PropTypes.string,
    onLogIn: PropTypes.func,
    onClose:PropTypes.func,
    onLoggedIn:PropTypes.func
};

module.exports = Login;

import bindAll from 'lodash.bindall';
import PropTypes from 'prop-types';
import React from 'react';
import {connect} from 'react-redux';

import {
    activateGoogleMapDeck,
    closeGoogleMap,
    nextGoogleMapStep,
    prevGoogleMapStep,
    dragGoogleMap,
    startGoogleMapDrag,
    endGoogleMapDrag
} from '../reducers/googlemap';

import GoogleMapComponent from '../components/cards/googlemap.jsx';

class GoogleMap extends React.Component {
    constructor (props) {
        super(props);
        bindAll(this, [
            'handleGetBook',
            'setRef',
            'handlePrevStep',
            'handleNextStep'
        ]);
    }
    handlePrevStep(){

    }
    handleNextStep(){
        
    }
    handleGetBook (url) {
        window.open(url,'_blank');
    }
    componentDidMount () {
        try {
            document.googlemap_init(this.props.activeDeckId);    
        } catch (error) {
            
        }

        
    }
    componentWillUnmount () {
        try {
            document.googlemap_stop();
        } catch (error) {
            
        }
        
    }
    setRef (ref) {
        this.ref = ref;
    }
    render () {
        const {
            ...props
        } = this.props;
        return (
            <GoogleMapComponent
                {...props}
            />
        );
    }
}
GoogleMap.propTypes = {
    activeDeckId: PropTypes.string,
    content: PropTypes.shape({
        id: PropTypes.shape({
            name: PropTypes.node.isRequired,
            img: PropTypes.string.isRequired,
            steps: PropTypes.arrayOf(PropTypes.shape({
                title: PropTypes.node,
                image: PropTypes.string,
                video: PropTypes.string,
                deckIds: PropTypes.arrayOf(PropTypes.string)
            }))
        })
    }),
    dragging: PropTypes.bool.isRequired,
    isRtl: PropTypes.bool.isRequired,
    locale: PropTypes.string.isRequired,
    onActivateDeckFactory: PropTypes.func.isRequired,
    onCloseCards: PropTypes.func.isRequired,
    onDrag: PropTypes.func,
    onEndDrag: PropTypes.func,
    onNextStep: PropTypes.func.isRequired,
    onPrevStep: PropTypes.func.isRequired,
    onShowAll: PropTypes.func,
    onStartDrag: PropTypes.func,
    step: PropTypes.number.isRequired,
    x: PropTypes.number,
    y: PropTypes.number
};
const mapStateToProps = state => ({
    visible: state.scratchGui.googlemap.visible,
    content: state.scratchGui.googlemap.content,
    activeDeckId: state.scratchGui.googlemap.activeDeckId,
    step: state.scratchGui.googlemap.step,
    x: state.scratchGui.googlemap.x,
    y: state.scratchGui.googlemap.y,
    isRtl: state.locales.isRtl,
    locale: state.locales.locale,
    dragging: state.scratchGui.googlemap.dragging
});

const mapDispatchToProps = dispatch => ({
    onActivateDeckFactory: id => () => dispatch(activateGoogleMapDeck(id)),
    onShowAll: () =>dispatch(closeGoogleMap()),
    onCloseCards: () => dispatch(closeGoogleMap()),
    onNextStep: () => dispatch(nextGoogleMapStep()),
    onPrevStep: () => dispatch(prevGoogleMapStep()),
    onDrag: (e_, data) => dispatch(dragGoogleMap(data.x, data.y)),
    onStartDrag: () => dispatch(startGoogleMapDrag()),
    onEndDrag: () => dispatch(endGoogleMapDrag())
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(GoogleMap);

import PropTypes from 'prop-types';
import React from 'react';
import ReactModal from 'react-modal';
import Box from '../box/box.jsx';
import {defineMessages, injectIntl, intlShape, FormattedMessage} from 'react-intl';

import styles from './preview-modal.css';
import catIcon from './happy-cat.png';


const messages = defineMessages({
    label: {
        id: 'gui.previewInfo.label',
        defaultMessage: 'Try Scratch 3.0',
        description: 'Scratch 3.0 modal label - for accessibility'
    },
    previewWelcome: {
        defaultMessage: 'Welcome to the Scratch 3.0 Beta',
        description: 'Header for Preview Info Modal',
        id: 'gui.previewInfo.welcome'
    },
    notNowTooltip: {
        defaultMessage: 'Not Now',
        description: 'Tooltip for Not Now button',
        id: 'gui.previewModal.notnowtooltip'
    },
    tryItTooltip: {
        defaultMessage: 'Try It',
        description: 'Tooltip for Try It button',
        id: 'gui.previewModal.tryittooltip'
    },
    viewProjectTooltip: {
        defaultMessage: 'View 2.0 Project',
        description: 'Tooltip for View 2.0 Project button',
        id: 'gui.previewModal.viewprojecttooltip'
    }
});

const PreviewModal = ({intl, ...props}) => (
    <ReactModal
        isOpen
        className={styles.modalContent}
        contentLabel={intl.formatMessage({...messages.label})}
        overlayClassName={styles.modalOverlay}
        onRequestClose={props.onTryIt}
    >
        <div dir={props.isRtl ? 'rtl' : 'ltr'} >
            <Box className={styles.illustration} />

            <Box className={styles.body}>
                <h2>
                    <FormattedMessage
                        defaultMessage="Welcome!"
                        description="Label for button to Common Coding Builder"
                        id="gui.previewModal.welcome.title"
                        
                    />
                    <br />
                    <FormattedMessage
                            defaultMessage="Common Coding Builder!"
                            description="Label for button to Common Coding Builder"
                            id="gui.previewModal.welcome.message"
                            
                        />
                 
                </h2>
                <p>
                    { /* eslint-disable max-len */ }
                    <FormattedMessage
                        defaultMessage="We're working for the Kidult! We will make more extensions that Scratch does not support."
                        description="Builder description"
                        id="gui.previewInfo.invitation.commoncoding"
                    />
                    { /* eslint-enable max-len */ }
                </p>

                <Box className={styles.buttonRow}>
                    {/*<button
                        className={styles.noButton}
                        title={intl.formatMessage(messages.notNowTooltip)}
                        onClick={props.onCancel}
                    >
                        <FormattedMessage
                            defaultMessage="Not Now"
                            description="Label for button to back out of trying Scratch 3.0 Beta"
                            id="gui.previewInfo.notnow"
                        />
                    </button>*/}
                    <button
                        className={styles.okButton}
                        title={intl.formatMessage(messages.tryItTooltip)}
                        onClick={props.onTryIt}
                    >
                        <FormattedMessage
                            defaultMessage="Try It!"
                            description="Label for button to Common Coding Builder"
                            id="gui.previewModal.start"
                            
                        />
                    </button>
                    <button
                        className={styles.viewProjectButton}
                        title={intl.formatMessage(messages.viewProjectTooltip)}
                        onClick={props.onViewProject}
                    >
                        <FormattedMessage
                            defaultMessage="Tutorials"
                            description="Label for button to try Common Coding Builder"
                            id="gui.menuBar.tutorialsLibrary"
                            
                        />
                    </button>
                </Box>
                <Box className={styles.faqLinkText}>
                    <FormattedMessage
                        defaultMessage="Our Instagram {offical} {builder}."
                        description="Invitation to try Common Coding Builder Instagram"
                        id="gui.previewInfo.instagram"
                        values={
                        {
                            offical: (
                                <a
                                    className={styles.faqLink}
                                    href="https://www.instagram.com/commoncoding.official/"
                                >
                                    @commoncoding.official
                                </a>
                            ),
                            builder: (
                                <a
                                    className={styles.faqLink}
                                    href="https://www.instagram.com/commoncoding.builder/"
                                >
                                    @commoncoding.builder


                                </a>
                            ),

                        }
                        }
                    />
                </Box>
            </Box>
        </div>
    </ReactModal>
);

PreviewModal.propTypes = {
    intl: intlShape.isRequired,
    isRtl: PropTypes.bool,
    onCancel: PropTypes.func.isRequired,
    onTryIt: PropTypes.func.isRequired,
    onViewProject: PropTypes.func.isRequired
};

export default injectIntl(PreviewModal);

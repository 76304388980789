import PropTypes from 'prop-types';
import React from 'react';
import bindAll from 'lodash.bindall';
import ConnectionModalComponent, {PHASES} from '../components/connection-modal/connection-modal.jsx';
import VM from 'scratch-vm';
//import analytics from '../lib/analytics';
import extensionData from '../lib/libraries/extensions/index.jsx';
import {connect} from 'react-redux';
import {closeConnectionModal} from '../reducers/modals';

class ConnectionModal extends React.Component {
    constructor (props) {
        super(props);
        bindAll(this, [
            'handleScanning',
            'handleCancel',
            'handleConnected',
            'handleConnecting',
            'handleDisconnect',
            'handleError',
            'handleHelp',
            'handleDownload',
            'handleHexDownload',
            'handleFirmwareUpdateScanning',
            'handleFirmwareUpdateConnected',
            'handleFirmwareUpdateRunning',
            'handleFirmwareUpdateError'
        ]);
        this.state = {
            extension: extensionData.find(ext => ext.extensionId === props.extensionId),
            /* phase: props.vm.getPeripheralIsConnected(props.extensionId) ?
                PHASES.connected : PHASES.scanning */
            //lch1721 todo if multi connection device? phases.scanning, else props.vm.getPeripheralIsConnected(props.extensionId) ?PHASES.connected : PHASES.scanning
            phase: PHASES.scanning
        };
    }
    componentDidMount () {
        this.props.vm.on('PERIPHERAL_FIRMWARE_UPDATED', this.handleFirmwareUpdateConnected);
        this.props.vm.on('PERIPHERAL_CONNECTED', this.handleConnected);
        this.props.vm.on('PERIPHERAL_REQUEST_ERROR', this.handleError);
    }
    componentWillUnmount () {
        this.props.vm.removeListener('PERIPHERAL_FIRMWARE_UPDATED', this.handleFirmwareUpdateConnected);
        this.props.vm.removeListener('PERIPHERAL_CONNECTED', this.handleConnected);
        this.props.vm.removeListener('PERIPHERAL_REQUEST_ERROR', this.handleError);
    }
    handleScanning () {
        this.setState({
            phase: PHASES.scanning
        });
    }
    handleFirmwareUpdateScanning () {
        console.log("handleFirmwareUpdateScanning");
        this.setState({
            phase: PHASES.fuScanning
        });
    }
    handleConnecting (peripheralId) {
        this.props.vm.connectPeripheral(this.props.extensionId, peripheralId);
        this.setState({
            phase: PHASES.connecting
        });
        /* analytics.event({
            category: 'extensions',
            action: 'connecting',
            label: this.props.extensionId
        }); */
    }
    handleFirmwareUpdateRunning (peripheralId) {
        this.props.vm.firmwareUpdating(this.props.extensionId, peripheralId);
        this.setState({
            phase: PHASES.fuUploading
        });
    }
    handleDisconnect () {
        try {
            this.props.vm.disconnectPeripheral(this.props.extensionId);
        } finally {
            this.props.onCancel();
        }
    }
    handleCancel () {
        try {
            // If we're not connected to a peripheral, close the websocket so we stop scanning.
            if (!this.props.vm.getPeripheralIsConnected(this.props.extensionId)) {
                this.props.vm.disconnectPeripheral(this.props.extensionId);
            }
        } finally {
            // Close the modal.
            this.props.onCancel();
        }
    }
    handleError () {
        // Assume errors that come in during scanning phase are the result of not
        // having scratch-link installed.
        if (this.state.phase === PHASES.scanning || this.state.phase === PHASES.unavailable) {
            this.setState({
                phase: PHASES.unavailable
            });
        } else {
            this.setState({
                phase: PHASES.error
            });
            /* analytics.event({
                category: 'extensions',
                action: 'connecting error',
                label: this.props.extensionId
            }); */
        }
    }
    handleFirmwareUpdateError () {
        // Assume errors that come in during scanning phase are the result of not
        // having scratch-link installed.
        if (this.state.phase === PHASES.fuScanning || this.state.phase === PHASES.unavailable) {
            this.setState({
                phase: PHASES.unavailable
            });
        } else {
            this.setState({
                phase: PHASES.fuError
            });
            /* analytics.event({
                category: 'extensions',
                action: 'connecting error',
                label: this.props.extensionId
            }); */
        }
    }
    handleConnected () {
        this.setState({
            phase: PHASES.connected
        });
        /* analytics.event({
            category: 'extensions',
            action: 'connected',
            label: this.props.extensionId
        }); */
    }
    handleFirmwareUpdateConnected () {
        this.setState({
            phase: PHASES.fuUploaded
        });

    }
    handleHelp () {
        window.open(this.state.extension.helpLink, '_blank');
        /* analytics.event({
            category: 'extensions',
            action: 'help',
            label: this.props.extensionId
        }); */
    }
    handleDownload() {
        window.open('/static/ccb_connect.zip', '_blank');
        /* analytics.event({
            category: 'extensions',
            action: 'download',
            label: this.props.extensionId
        }); */
    }
    
    handleHexDownload(){
        if (/(iP)/g.test(navigator.userAgent)) {
            window.open('/static/ccb_firmata.ino.hex', '_blank');
        }
        var sUrl = '/static/ccb_firmata.ino.hex';
        //If in Chrome or Safari - download via virtual link click
        if (navigator.userAgent.toLowerCase().indexOf('chrome') > -1 || navigator.userAgent.toLowerCase().indexOf('safari') > -1) {
            //Creating new link node.
            var link = document.createElement('a');
            link.href = sUrl;
    
            if (link.download !== undefined) {
                //Set HTML5 download attribute. This will prevent file from opening if supported.
                var fileName = sUrl.substring(sUrl.lastIndexOf('/') + 1, sUrl.length);
                link.download = fileName;
            }
    
            //Dispatching click event.
            if (document.createEvent) {
                var e = document.createEvent('MouseEvents');
                e.initEvent('click', true, true);
                link.dispatchEvent(e);
                return;
            }
        }
    
        // Force file download (whether supported by server).
        if (sUrl.indexOf('?') === -1) {
            sUrl += '?download';
        }
    
        window.open(sUrl, '_self');
        // window.open('/static/ccb_firmata.ino.hex', '_blank');
    }
    render () {
        return (
            <ConnectionModalComponent
                connectingMessage={this.state.extension.connectingMessage}
                extensionId={this.props.extensionId}
                name={this.state.extension.name}
                peripheralButtonImage={this.state.extension.peripheralButtonImage}
                peripheralImage={this.state.extension.peripheralImage}
                phase={this.state.phase}
                smallPeripheralImage={this.state.extension.smallPeripheralImage}
                title={this.props.extensionId}
                useAutoScan={this.state.extension.useAutoScan}
                vm={this.props.vm}
                onCancel={this.handleCancel}
                onConnected={this.handleConnected}
                onConnecting={this.handleConnecting}
                onDisconnect={this.handleDisconnect}
                onHelp={this.handleHelp}
                onDownload={this.handleDownload}
                onFirmwareUpdateScanning={this.handleFirmwareUpdateScanning}
                onFirmwareUpdateRunning={this.handleFirmwareUpdateRunning}
                handleFirmwareUpdateStart={this.handleFirmwareUpdateRunning}
                onScanning={this.handleScanning}
            />
        );
    }
}

ConnectionModal.propTypes = {
    extensionId: PropTypes.string.isRequired,
    onCancel: PropTypes.func.isRequired,
    vm: PropTypes.instanceOf(VM).isRequired
};

const mapStateToProps = state => ({
    extensionId: state.scratchGui.connectionModal.extensionId
});

const mapDispatchToProps = dispatch => ({
    onCancel: () => {
        dispatch(closeConnectionModal());
    }
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ConnectionModal);

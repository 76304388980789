import ScratchStorage from 'scratch-storage';

import defaultProject from './default-project';

/**
 * Wrapper for ScratchStorage which adds default web sources.
 * @todo make this more configurable
 */
class Storage extends ScratchStorage {
    constructor () {
        super();
        this.cacheDefaultProject();
        this.isLocalTest = false;
    }
    addOfficialScratchWebStores () {
        this.addWebStore(
            [this.AssetType.Project],
            this.getProjectGetConfig.bind(this),
            this.getProjectCreateConfig.bind(this),
            this.getProjectUpdateConfig.bind(this)
        );
        this.addWebStore(
            [this.AssetType.ImageVector, this.AssetType.ImageBitmap, this.AssetType.Sound],
            this.getAssetGetConfig.bind(this),
            // We set both the create and update configs to the same method because
            // storage assumes it should update if there is an assetId, but the
            // asset store uses the assetId as part of the create URI.
            this.getAssetCreateConfig.bind(this),
            this.getAssetCreateConfig.bind(this)
        );
        this.addWebStore(
            [this.AssetType.Sound],
            asset => `static/extension-assets/scratch3_music/${asset.assetId}.${asset.dataFormat}`
        );
    }
    setProjectHost (projectHost) {
        this.projectHost = projectHost;
    }
    getProjectGetConfig (projectAsset) {
        console.log("storage getProjectGetConfig");
        console.log(projectAsset);
        //return `${this.projectHost}/${projectAsset.assetId}`;
        //
        if(this.isLocalTest){
            return `static/projects/${projectAsset.assetId}.zip`;
        } else {
            return `/api/v1/project/get/${projectAsset.assetId}`;
        }
        
    }
    getProjectCreateConfig () {
        console.log("storage getProjectCreateConfig");
        if(this.isLocalTest){
            return {
                //url: `${this.projectHost}/`,
                url: `/`,
                withCredentials: true
            };
        } else {
            return {
                url: `/api/v1/project/create`,
                withCredentials: true
            };
        }
        /**/
        //lch1721 0914
        
    }
    getProjectUpdateConfig (projectAsset) {
        console.log("storage getProjectUpdateConfig");
        console.log(projectAsset);
        if(this.isLocalTest){
            return {
                //url: `${this.projectHost}/${projectAsset.assetId}`,
                url: `projects/${projectAsset.assetId}`,
                withCredentials: true
            };
        } else {
            return {
                url: `/api/v1/project/save/${projectAsset.assetId}`,
                withCredentials: true
            };
        }
        /**/
        //lch1721 0914
        
    }
    setAssetHost (assetHost) {
        this.assetHost = assetHost;
    }
    getAssetGetConfig (asset) {
        console.log("storage getAssetGetConfig");
        console.log(asset);
        //return `${this.assetHost}/internalapi/asset/${asset.assetId}.${asset.dataFormat}/get/`;
        if(this.isLocalTest){
            return `static/asset/${asset.assetId}.${asset.dataFormat}`;
        } else {
            return `/api/v1/asset/get/${asset.dataFormat}/${asset.assetId}`;
        }
    }
    getAssetCreateConfig (asset) {
        console.log("storage getAssetCreateConfig");
        console.log(asset);
        if(this.isLocalTest){
            return {
                // There is no such thing as updating assets, but storage assumes it
                // should update if there is an assetId, and the asset store uses the
                // assetId as part of the create URI. So, force the method to POST.
                // Then when storage finds this config to use for the "update", still POSTs
                method: 'post',
                url: `${this.assetHost}/${asset.assetId}.${asset.dataFormat}`,
                withCredentials: true
            };
        } else {
            return {
                // There is no such thing as updating assets, but storage assumes it
                // should update if there is an assetId, and the asset store uses the
                // assetId as part of the create URI. So, force the method to POST.
                // Then when storage finds this config to use for the "update", still POSTs
                method: 'post',
                url: `/api/v1/asset/save/${asset.dataFormat}/${asset.assetId}`,
                withCredentials: true
            };
        }        
    }
    setTranslatorFunction (translator) {
        this.translator = translator;
        this.cacheDefaultProject();
    }
    cacheDefaultProject () {
        const defaultProjectAssets = defaultProject(this.translator);
        defaultProjectAssets.forEach(asset => this.builtinHelper._store(
            this.AssetType[asset.assetType],
            this.DataFormat[asset.dataFormat],
            asset.data,
            asset.id
        ));
    }
}

const storage = new Storage();

export default storage;

import PropTypes from 'prop-types';
import React from 'react';
import bindAll from 'lodash.bindall';
import SpeechRecognitionComponent from '../components/question/speech-recognition.jsx';

class SpeechRecognition extends React.Component {
    constructor (props) {
        super(props);
        bindAll(this, [
            'handleChange',
            'handleKeyPress',
            'handleSubmit',
            'handleMic',
            'changeAnswer'
        ]);
        window.OnSpeechRecognition = this.changeAnswer.bind(this);
        this.state = {
            answer: ''
        };
    }
    componentDidMount () {
        console.log("SpeechRecognition componentDidMount")
        if (typeof ccb_android !== 'undefined') {
            ccb_android.startSpeechRecognition();
        }
        
    }
    handleChange (e) {
        this.setState({answer: e.target.value});
    }
    handleKeyPress (event) {
        if (event.key === 'Enter') this.handleSubmit();
    }
    handleMic(){
        console.log("handleMic call");
        if (typeof ccb_android !== 'undefined') {
            ccb_android.startSpeechRecognition();
        }
    }
    handleSubmit () {
        this.props.onQuestionAnswered(this.state.answer);
    }
    changeAnswer(value){
        console.log("changeAnswer call");
        this.setState({answer: value});
    }
    render () {
        return (
            <SpeechRecognitionComponent
                answer={this.state.answer}
                question={this.props.question}
                onChange={this.handleChange}
                onClick={this.handleSubmit}
                onMicClick={this.handleMic}
                onKeyPress={this.handleKeyPress}
            />
        );
    }
}

SpeechRecognition.propTypes = {
    onQuestionAnswered: PropTypes.func.isRequired,
    question: PropTypes.string
};

export default SpeechRecognition;

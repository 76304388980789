import bindAll from 'lodash.bindall';
import PropTypes from 'prop-types';
import React from 'react';

import LoginFormComponent from '../components/login-form/login-form.jsx';
const sessionActions = require('../reducers/session.js');

class LoginForm extends React.Component {
    constructor (props) {
        super(props);
        bindAll(this, [
            'addListeners',
            'removeListeners',
            'handleClick'
        ]);
    }
    componentDidMount () {
        
        if (this.props.open) this.addListeners();
        /*firebase.auth().onAuthStateChanged(function(user) {
            if(user){
                console.log("user login");
            } else {
                console.log("user null");
            }
            console.log("refreshSession call");
            sessionActions.refreshSession();
            this.props.onClose();
        }); */
        
        /*ui.disableAutoSignIn();
        ui.reset();
        console.log("ui config");
        console.log(getUiConfig());
        ui.start('#firebaseui-container', getUiConfig());*/
    }
    componentDidUpdate (prevProps) {
        if (this.props.open && !prevProps.open) this.addListeners();
        if (!this.props.open && prevProps.open) this.removeListeners();
    }
    componentWillUnmount () {
        this.removeListeners();
    }
    addListeners () {
        document.addEventListener('mouseup', this.handleClick);
    }
    removeListeners () {
        document.removeEventListener('mouseup', this.handleClick);
    }
    handleClick (formData) {
        
        if (this.props.open && !this.menu.contains(e.target)) {
            this.props.onRequestClose();
        }
        /*firebase.auth().signInWithEmailAndPassword(formData.username, formData.password).catch(function(error) {
          // Handle Errors here.
          var errorCode = error.code;
          var errorMessage = error.message;
          console.log("login error");
          console.log(error);
          sessionActions.refreshSession();
          // ...
        });*/
        
        

    }
    
    render () {
        const {
            onClose,
            ...props
        } = this.props;
        return (
            <LoginFormComponent
                onClose={onClose}
                handleSubmit={this.handleClick}
            >
            </LoginFormComponent>
        );
    }
}

LoginForm.propTypes = {
   onClose: PropTypes.func
};

export default LoginForm;

const keyMirror = require('keymirror');
const defaults = require('lodash.defaults');

const {requestSession, requestSessionWithRetry, requestSession2} = require('../lib/session');
const messageCountActions = require('./message-count.js');
const permissionsActions = require('./permissions.js');

const Types = keyMirror({
    SET_SESSION: null,
    SET_SESSION_ERROR: null,
    SET_STATUS: null
});

const banWhitelistPaths = [
    '/accounts/banned-response/',
    '/community_guidelines/',
    '/community_guidelines'
];

module.exports.Status = keyMirror({
    FETCHED: null,
    NOT_FETCHED: null,
    FETCHING: null
});

module.exports.getInitialState = () => ({
    status: module.exports.Status.NOT_FETCHED,
    session: {}
});

module.exports.sessionReducer = (state, action) => {
    // Reducer for handling changes to session state
    if (typeof state === 'undefined') {
        state = module.exports.getInitialState();
    }
    switch (action.type) {
    case Types.SET_SESSION:
        return defaults({session: action.session}, state);
    case Types.SET_STATUS:
        return defaults({status: action.status}, state);
    case Types.SET_SESSION_ERROR:
        // TODO: do something with action.error
        return state;
    default:
        return state;
    }
};

module.exports.setSessionError = error => ({
    type: Types.SET_SESSION_ERROR,
    error: error
});

module.exports.setSession = session => ({
    type: Types.SET_SESSION,
    session: session
});

module.exports.setStatus = status => ({
    type: Types.SET_STATUS,
    status: status
});

const handleSessionResponse = (dispatch, body) => {
    if (typeof body === 'undefined') return dispatch(module.exports.setSessionError('No session content'));
    if (
        body.user &&
        body.user.banned &&
        banWhitelistPaths.indexOf(window.location.pathname) === -1
    ) {
        window.location = '/accounts/banned-response/';
        return;
    } else if (
        body.flags &&
        body.flags.must_complete_registration &&
        window.location.pathname !== '/classes/complete_registration'
    ) {
        window.location = '/classes/complete_registration';
        return;
    } else if (
        body.flags &&
        body.flags.must_reset_password &&
        !body.flags.must_complete_registration &&
        window.location.pathname !== '/classes/student_password_reset/'
    ) {
        window.location = '/classes/student_password_reset/';
        return;
    }
    dispatch(module.exports.setSession(body));
    dispatch(module.exports.setStatus(module.exports.Status.FETCHED));

    // get the permissions from the updated session
    dispatch(permissionsActions.storePermissions(body.permissions));
    if (typeof body.user !== 'undefined') {
        dispatch(messageCountActions.getCount(body.user.username));
    }
    return;
};

module.exports.refreshSession = () => (dispatch => {
    dispatch(module.exports.setStatus(module.exports.Status.FETCHING));
    return new Promise((resolve, reject) => {
        requestSession(resolve, reject);
    }).then(body => {
        handleSessionResponse(dispatch, body);
    }, err => {
        dispatch(module.exports.setSessionError(err));
    });
});

module.exports.refreshSessionWithRetry = () => (dispatch => {
    dispatch(module.exports.setStatus(module.exports.Status.FETCHING));
    return new Promise((resolve, reject) => {
        requestSessionWithRetry(resolve, reject, 4, 7500);
    }).then(body => {
        handleSessionResponse(dispatch, body);
    }, err => {
        dispatch(module.exports.setSessionError(err));
    });
});

const handleSessionResponse2 = (dispatch, body) => {
    if (typeof body === 'undefined') return dispatch(module.exports.setSessionError('No session content'));
    
    
    dispatch(module.exports.setSession({
        "user": {
            "id": body.id,
            "banned":  false,
            "username": body.username,
            "token": "234d24b451bf4d1d96588bc989a62b93:36auSNa2JONCwLKzD0g7Huh6LWk",
            "thumbnailUrl": "//cdn2.scratch.mit.edu/get_image/user/default_32x32.png",
            "dateJoined": "2014-02-24T04:57:39",
            "email": body.uid

        },
        "permissions": {
            "admin": false,
            "scratcher": true,
            "new_scratcher": false,
            "social": false,
            "educator": false,
            "educator_invitee": false,
            "student": false
        },
        "flags": {
            "must_reset_password": false,
            "must_complete_registration": false,
            "has_outstanding_email_confirmation": false,
            "show_welcome": true,
            "confirm_email_banner": true,
            "unsupported_browser_banner": true
        }

    }));

    dispatch(module.exports.setStatus(module.exports.Status.FETCHED));

    // get the permissions from the updated session
    dispatch(permissionsActions.storePermissions({"permissions": {
        "admin": false,
        "scratcher": true,
        "new_scratcher": false,
        "social": false,
        "educator": false,
        "educator_invitee": false,
        "student": false
    }}));
    
    return;
};

module.exports.refreshSession2 = () => (dispatch => {
    console.log("refreshSession2");
    dispatch(module.exports.setStatus(module.exports.Status.FETCHING));
    return new Promise((resolve, reject) => {
        requestSession2(resolve, reject);
    }).then(body => {
        handleSessionResponse2(dispatch, body);
    }, err => {
        dispatch(module.exports.setSessionError(err));
    });
});


module.exports.refreshSession3 = () => (dispatch => {
    console.log("refreshSession3 start");
    var user = document.currentUser;

    if (user) {
        console.log("refreshSession has user");
        console.log(user);
        var displayName = user.username;
        var email = user.uid;
        // var emailVerified = user.emailVerified;
        // var photoURL = user.photoURL;
        // var isAnonymous = user.isAnonymous;
        var uid = user.uid;
        // var providerData = user.providerData;
        // ...
        dispatch(module.exports.setSession({
            "user": {
                "id": uid,
                "banned":  false,
                "username": displayName,
                "token": "234d24b451bf4d1d96588bc989a62b93:36auSNa2JONCwLKzD0g7Huh6LWk",
                "thumbnailUrl": "//cdn2.scratch.mit.edu/get_image/user/default_32x32.png",
                "dateJoined": "2014-02-24T04:57:39",
                "email": email

            },
            "permissions": {
                "admin": false,
                "scratcher": true,
                "new_scratcher": false,
                "social": true,
                "educator": false,
                "educator_invitee": false,
                "student": false
            },
            "flags": {
                "must_reset_password": false,
                "must_complete_registration": false,
                "has_outstanding_email_confirmation": false,
                "show_welcome": false,
                "confirm_email_banner": false,
                "unsupported_browser_banner": false
            }

        }));

        dispatch(permissionsActions.storePermissions({"permissions": {
            "admin": false,
            "scratcher": true,
            "new_scratcher": false,
            "social": false,
            "educator": false,
            "educator_invitee": false,
            "student": false
        }}));
        
    } else {
        console.log("refreshSession no user");
        dispatch(module.exports.setSessionError('No session content'));
        
    }
    
});
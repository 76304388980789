exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "\n.button_button_1z6YG {\n    display: inline-block;\n    margin: .5em 0;\n    border: 0;\n    border-radius: .5rem;\n    background-color: hsla(215, 100, 65, 1);\n    cursor: pointer;\n    padding: 1em 1.25em;\n    color: hsla(0, 100, 100, 1); \n    font-size: .8rem;\n    font-weight: bold;\n\n    /* USER BUTTON STATES */\n    &:focus {\n        outline: none;\n    }\n\n    /* DATA BUTTON STATES */\n    &.button_white_9iHih {\n        background-color: hsla(0, 100%, 100%, 1);\n        color: hsla(215, 100, 65, 1);\n    }\n\n    &.button_pass_1bPLR {\n        background-color: hsla(163, 85, 40, 1);\n    }\n\n    &.button_fail_EYjUG {\n        background-color: hsla(38, 100, 55, 1);\n    }\n\n    &:disabled {\n        -webkit-box-shadow: none;\n                box-shadow: none;\n    }\n}\n", ""]);

// exports
exports.locals = {
	"button": "button_button_1z6YG",
	"white": "button_white_9iHih",
	"pass": "button_pass_1bPLR",
	"fail": "button_fail_EYjUG"
};
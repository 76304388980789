const bindAll = require('lodash.bindall');
import PropTypes from 'prop-types';
import React from 'react';
import {compose} from 'redux';
import {connect} from 'react-redux';
import ReactModal from 'react-modal';
import VM from 'scratch-vm';
import {defineMessages, injectIntl, intlShape} from 'react-intl';

import ErrorBoundaryHOC from '../lib/error-boundary-hoc.jsx';
import {
    getIsError,
    getIsShowingProject
} from '../reducers/project-state';
import {setProjectTitle} from '../reducers/project-title';
import {
    activateTab,
    BLOCKS_TAB_INDEX,
    COSTUMES_TAB_INDEX,
    SOUNDS_TAB_INDEX
} from '../reducers/editor-tab';

import {
    closeCostumeLibrary,
    closeBackdropLibrary,
    closeTelemetryModal,
    openExtensionLibrary
} from '../reducers/modals';

import FontLoaderHOC from '../lib/font-loader-hoc.jsx';
import LocalizationHOC from '../lib/localization-hoc.jsx';
import ProjectFetcherHOC from '../lib/project-fetcher-hoc.jsx';
import ProjectSaverHOC from '../lib/project-saver-hoc.jsx';
import QueryParserHOC from '../lib/query-parser-hoc.jsx';
import storage from '../lib/storage';
import vmListenerHOC from '../lib/vm-listener-hoc.jsx';
import vmManagerHOC from '../lib/vm-manager-hoc.jsx';
import cloudManagerHOC from '../lib/cloud-manager-hoc.jsx';

import GUIComponent from '../components/gui/gui.jsx';
import {setIsScratchDesktop} from '../lib/isScratchDesktop.js';
import firebase from 'firebase';

import {activateFaceApiDeck, closeFaceApi} from '../reducers/faceapi';
import {activateTeachableMachineDeck, closeTeachableMachine} from '../reducers/teachablemachine';
import {activateZXingDeck, closeZXing} from '../reducers/zxing';
import {activateD3jsDeck, closeD3js} from '../reducers/d3js';
import {activateGoogleMapDeck, closeGoogleMap} from '../reducers/googlemap';

const ConnectedLogin = require('../components/login/connected-login.jsx');
const messages = defineMessages({
    defaultProjectTitle: {
        id: 'gui.gui.defaultProjectTitle', 
        description: 'Default title for project',
        defaultMessage: 'My Project'
    }
});

var config = {
    apiKey: "AIzaSyCR_tztwCruX278fgUEUbRRlPVcx5zBYZU",
    authDomain: "common-coding-builder.firebaseapp.com",
    databaseURL: "https://common-coding-builder.firebaseio.com",
    projectId: "common-coding-builder",
    storageBucket: "common-coding-builder.appspot.com",
    messagingSenderId: "164366102175"
};
firebase.initializeApp(config);

const navigationActions = require('../reducers/navigation.js');

class GUI extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
          ccUserName: null,
          ccUserEmail: null,
          ccUserID: null,
        }
        this.setUserEmail = this.setUserEmail.bind(this);
        bindAll(this, [
            'renderLogin',
            'faceApiModalListener',
            'teachableMachineModalListener',
            'teachableMachineModalCloseListener',
            'zxingModalListener',
            'zxingModalCloseListener',
            'd3jsModalListener',
            'd3jsModalCloseListener',
            'googlemapModalListener',
            'googlemapModalCloseListener',
            'setUserToSession'
        ]);
    }
    onLogOut(){
        // firebase.auth().signOut().then(function() {
          
        // }).catch(function(error) {
        //   // An error happened.
        // });
        if(document.ajaxLogout){
            document.ajaxLogout();
        }

        this.setState({ccUserName:null,
            ccUserEmail:null,
            ccUserID:null
        });
        this.setState({session:null});
    }
    setUserEmail(email){
        
        this.setState({ccUserName:email});
    }
    setUserToSession(uid,username,id){
        this.setState({ccUserName:username,
            ccUserEmail:uid,
            ccUserID:id
        });
        this.setState({session:
        {
            "user": {
                "id": id,
                "banned":  false,
                "username": username,
                "token": "234d24b451bf4d1d96588bc989a62b93:36auSNa2JONCwLKzD0g7Huh6LWk",
                "thumbnailUrl": "//cdn2.scratch.mit.edu/get_image/user/default_32x32.png",
                "dateJoined": "2014-02-24T04:57:39",
                "email": uid

            },
            "permissions": {
                "admin": false,
                "scratcher": true,
                "new_scratcher": false,
                "social": true,
                "educator": false,
                "educator_invitee": false,
                "student": false
            },
            "flags": {
                "must_reset_password": false,
                "must_complete_registration": false,
                "has_outstanding_email_confirmation": false,
                "show_welcome": false,
                "confirm_email_banner": false,
                "unsupported_browser_banner": false
            },
            "session":{
                "user": {
                    "id": id,
                    "banned":  false,
                    "username": username,
                    "token": "234d24b451bf4d1d96588bc989a62b93:36auSNa2JONCwLKzD0g7Huh6LWk",
                    "thumbnailUrl": "//cdn2.scratch.mit.edu/get_image/user/default_32x32.png",
                    "dateJoined": "2014-02-24T04:57:39",
                    "email": uid
    
                },
                "permissions": {
                    "admin": false,
                    "scratcher": true,
                    "new_scratcher": false,
                    "social": true,
                    "educator": false,
                    "educator_invitee": false,
                    "student": false
                },
                "flags": {
                    "must_reset_password": false,
                    "must_complete_registration": false,
                    "has_outstanding_email_confirmation": false,
                    "show_welcome": false,
                    "confirm_email_banner": false,
                    "unsupported_browser_banner": false
                }
            }
        }
            

        });
        
    }
    faceApiModalListener(onOff){
        
        if(onOff == "ON"){
            this.props.onActivateFaceApi();
        } else {
            document.faceApi_stop();
            this.props.onCloseFaceApi();
            
        }
    }
    teachableMachineModalListener(url){
        document.teachableMachine_url = url;
        this.props.onActivateTeachableMachine();
        
    }
    teachableMachineModalCloseListener(url){
        this.props.onCloseTeachableMachine();
        
    }
    zxingModalListener(){
        this.props.onActivateZXing();
        
    }
    zxingModalCloseListener(url){
        this.props.onCloseZXing();
        
    }
    d3jsModalListener(type){
        this.props.onActivateD3js(type);
        
    }
    d3jsModalCloseListener(url){
        this.props.onCloseD3js();
        
    }
    googlemapModalListener(type){
        this.props.onActivateGoogleMap(type);
        
    }
    googlemapModalCloseListener(url){
        this.props.onCloseGoogleMap();
        
    }
    componentDidMount () {
        
        setIsScratchDesktop(this.props.isScratchDesktop);
        this.setReduxTitle(this.props.projectTitle);
        this.props.onStorageInit(storage);
        //this.loadExtensionFirst();//lch1721
        const setUserEmailFunction = this.setUserEmail.bind(this);
        const setUserToSessionFuction = this.setUserToSession.bind(this);
        if(document.ajaxText){
            document.ajaxText(setUserToSessionFuction);
        }
       
        this.props.vm.runtime.addListener('FACE_API_MODAL', this.faceApiModalListener);
        this.props.vm.runtime.addListener('Teachable_Machine_MODAL', this.teachableMachineModalListener);
        this.props.vm.runtime.addListener('Teachable_Machine_MODAL_CLOSE', this.teachableMachineModalCloseListener);

        this.props.vm.runtime.addListener('ZXing_MODAL', this.zxingModalListener);
        this.props.vm.runtime.addListener('ZXing_MODAL_CLOSE', this.zxingModalCloseListener);

        this.props.vm.runtime.addListener('D3js_MODAL', this.d3jsModalListener);
        this.props.vm.runtime.addListener('D3js_MODAL_CLOSE', this.d3jsModalCloseListener);

        this.props.vm.runtime.addListener('GoogleMap_MODAL', this.googlemapModalListener);
        this.props.vm.runtime.addListener('GoogleMap_MODAL_CLOSE', this.googlemapModalCloseListener);
        
    }
    componentWillUnmount () {
        this.props.vm.runtime.removeListener('FACE_API_MODAL', this.faceApiModalListener);
        this.props.vm.runtime.removeListener('Teachable_Machine_MODAL', this.teachableMachineModalListener);
        this.props.vm.runtime.removeListener('Teachable_Machine_MODAL_CLOSE', this.teachableMachineModalCloseListener);

        this.props.vm.runtime.removeListener('ZXing_MODAL', this.zxingModalListener);
        this.props.vm.runtime.removeListener('ZXing_MODAL_CLOSE', this.zxingModalCloseListener);

        this.props.vm.runtime.removeListener('D3js_MODAL', this.d3jsModalListener);
        this.props.vm.runtime.removeListener('D3jsg_MODAL_CLOSE', this.d3jsModalCloseListener);

        this.props.vm.runtime.removeListener('GoogleMap_MODAL', this.googlemapModalListener);
        this.props.vm.runtime.removeListener('GoogleMap_MODAL_CLOSE', this.googlemapModalCloseListener);
    }
    componentDidUpdate (prevProps) {
        if (this.props.projectId !== prevProps.projectId && this.props.projectId !== null) {
            this.props.onUpdateProjectId(this.props.projectId);
        }
        if (this.props.projectTitle !== prevProps.projectTitle) {
            this.setReduxTitle(this.props.projectTitle);
        }
        if (this.props.isShowingProject && !prevProps.isShowingProject) {
            // this only notifies container when a project changes from not yet loaded to loaded
            // At this time the project view in www doesn't need to know when a project is unloaded
            this.props.onProjectLoaded();
        }
    }
    onVideoClickFunction(){
        
        
    }
    setReduxTitle (newTitle) {
        if (newTitle === null || typeof newTitle === 'undefined') {
            this.props.onUpdateReduxProjectTitle(
                this.props.intl.formatMessage(messages.defaultProjectTitle)
            );
        } else {
            this.props.onUpdateReduxProjectTitle(newTitle);
        }
    }
    renderLogin ({onClose}) {
        return (
            <ConnectedLogin
                key="login-dropdown-presentation"
                /* eslint-disable react/jsx-no-bind */
                onLoggedIn={this.setUserToSession}
                onLogIn={(formData, callback) => {
                    this.props.handleLogIn(formData, result => {
                        if (result.success === true) {
                            onClose();
                        }
                        callback(result);
                    });
                }}
                /* eslint-ensable react/jsx-no-bind */
            />
        );
    }
    render () {
        const logOutFunction = this.onLogOut.bind(this);
        const onVideoClickFunction = this.onVideoClickFunction.bind(this);
        const onLoggedIn = this.setUserToSession.bind(this);
        let currentName = null;
        if(this.state){
            currentName = this.state.ccUserName;
        }
        
        if (this.props.isError) {
            alert("Can't open project");
            location.href ="/";
            // throw new Error(
            //     `Error in Scratch GUI [location=${window.location}]: ${this.props.error}`);
        }
        const {
            /* eslint-disable no-unused-vars */
            assetHost,
            cloudHost,
            error,
            isError,
            isScratchDesktop,
            isShowingProject,
            onProjectLoaded,
            onStorageInit,
            onUpdateProjectId,
            onUpdateReduxProjectTitle,
            projectHost,
            projectId,
            projectTitle,
            /* eslint-enable no-unused-vars */
            children,
            fetchingProject,
            isLoading,
            loadingStateVisible,
            ...componentProps
        } = this.props;
        return (
            <GUIComponent
                loading={fetchingProject || isLoading || loadingStateVisible}
                ccUserName={currentName}
                renderLogin={this.renderLogin}
                onLogOut={logOutFunction}
                onVideoClick={onVideoClickFunction}
                onLoggedIn={onLoggedIn}
                {...componentProps}
            >
                {children}
            </GUIComponent>
        );
    }
}

GUI.propTypes = {
    assetHost: PropTypes.string,
    children: PropTypes.node,
    cloudHost: PropTypes.string,
    error: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    fetchingProject: PropTypes.bool,
    importInfoVisible: PropTypes.bool,
    intl: intlShape,
    isError: PropTypes.bool,
    isLoading: PropTypes.bool,
    isScratchDesktop: PropTypes.bool,
    isShowingProject: PropTypes.bool,
    loadingStateVisible: PropTypes.bool,
    onProjectLoaded: PropTypes.func,
    onSeeCommunity: PropTypes.func,
    onStorageInit: PropTypes.func,
    onUpdateProjectId: PropTypes.func,
    onUpdateProjectTitle: PropTypes.func,
    onUpdateReduxProjectTitle: PropTypes.func,
    previewInfoVisible: PropTypes.bool,
    projectHost: PropTypes.string,
    projectId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    projectTitle: PropTypes.string,
    telemetryModalVisible: PropTypes.bool,
    vm: PropTypes.instanceOf(VM).isRequired,
    handleLogIn: PropTypes.func
};

GUI.defaultProps = {
    isScratchDesktop: false,
    onStorageInit: storageInstance => storageInstance.addOfficialScratchWebStores(),
    onProjectLoaded: () => {},
    onUpdateProjectId: () => {}
    
};

const mapStateToProps = state => {
    const loadingState = state.scratchGui.projectState.loadingState;
    return {
        activeTabIndex: state.scratchGui.editorTab.activeTabIndex,
        alertsVisible: state.scratchGui.alerts.visible,
        backdropLibraryVisible: state.scratchGui.modals.backdropLibrary,
        blocksTabVisible: state.scratchGui.editorTab.activeTabIndex === BLOCKS_TAB_INDEX,
        cardsVisible: state.scratchGui.cards.visible,
        connectionModalVisible: state.scratchGui.modals.connectionModal,
        faceModalVisible: state.scratchGui.faceapi.visible,//true,//
        teachableMachineVisible: state.scratchGui.teachablemachine.visible,//true,//
        zxingVisible: state.scratchGui.zxing.visible,//true,//
        d3jsVisible: state.scratchGui.d3js.visible,//true,//
        googlemapVisible: state.scratchGui.googlemap.visible,//true,//
        costumeLibraryVisible: state.scratchGui.modals.costumeLibrary,
        costumesTabVisible: state.scratchGui.editorTab.activeTabIndex === COSTUMES_TAB_INDEX,
        error: state.scratchGui.projectState.error,
        importInfoVisible: state.scratchGui.modals.importInfo,
        isError: getIsError(loadingState),
        isFullScreen: state.scratchGui.mode.isFullScreen,
        isPlayerOnly: state.scratchGui.mode.isPlayerOnly,
        isRtl: state.locales.isRtl,
        isShowingProject: getIsShowingProject(loadingState),
        loadingStateVisible: state.scratchGui.modals.loadingProject,
        previewInfoVisible: state.scratchGui.modals.previewInfo,
        projectId: state.scratchGui.projectState.projectId,
        soundsTabVisible: state.scratchGui.editorTab.activeTabIndex === SOUNDS_TAB_INDEX,
        targetIsStage: (
            state.scratchGui.targets.stage &&
            state.scratchGui.targets.stage.id === state.scratchGui.targets.editingTarget
        ),
        telemetryModalVisible: state.scratchGui.modals.telemetryModal,
        tipsLibraryVisible: state.scratchGui.modals.tipsLibrary,
        vm: state.scratchGui.vm
    };
};

const mapDispatchToProps = dispatch => ({
    onExtensionButtonClick: () => dispatch(openExtensionLibrary()),
    onActivateTab: tab => dispatch(activateTab(tab)),
    onActivateCostumesTab: () => dispatch(activateTab(COSTUMES_TAB_INDEX)),
    onActivateSoundsTab: () => dispatch(activateTab(SOUNDS_TAB_INDEX)),
    onRequestCloseBackdropLibrary: () => dispatch(closeBackdropLibrary()),
    onRequestCloseCostumeLibrary: () => dispatch(closeCostumeLibrary()),
    onRequestCloseTelemetryModal: () => dispatch(closeTelemetryModal()),
    onUpdateReduxProjectTitle: title => dispatch(setProjectTitle(title)),
    onActivateFaceApi: () => dispatch(activateFaceApiDeck('video1')),
    onCloseFaceApi: () => dispatch(closeFaceApi()),
    onActivateTeachableMachine: () => dispatch(activateTeachableMachineDeck('video1')),
    onCloseTeachableMachine: () => dispatch(closeTeachableMachine()),
    onActivateZXing: () => dispatch(activateZXingDeck('video1')),
    onCloseZXing: () => dispatch(closeZXing()),
    onActivateD3js: type => dispatch(activateD3jsDeck(type)),
    onCloseD3js: () => dispatch(closeD3js()),
    onActivateGoogleMap: type => dispatch(activateGoogleMapDeck('video1')),
    onCloseGoogleMap: () => dispatch(closeGoogleMap()),
    handleOpenRegistration: event => {
        event.preventDefault();
        //dispatch(navigationActions.setRegistrationOpen(true));
    },
    handleLogIn: (formData, callback) => {
        
        dispatch(navigationActions.handleLogIn(formData, callback));
    },
    handleLogOut: event => {
        event.preventDefault();
        //dispatch(navigationActions.handleLogOut());
    },
    handleToggleLoginOpen: event => {
        event.preventDefault();
        //dispatch(navigationActions.toggleLoginOpen());
    }
});

const ConnectedGUI = injectIntl(connect(
    mapStateToProps,
    mapDispatchToProps,
)(GUI));

// note that redux's 'compose' function is just being used as a general utility to make
// the hierarchy of HOC constructor calls clearer here; it has nothing to do with redux's
// ability to compose reducers.
const WrappedGui = compose(
    LocalizationHOC,
    ErrorBoundaryHOC('Top Level App'),
    FontLoaderHOC,
    QueryParserHOC,
    ProjectFetcherHOC,
    ProjectSaverHOC,
    vmListenerHOC,
    vmManagerHOC,
    cloudManagerHOC
)(ConnectedGUI);

WrappedGui.setAppElement = ReactModal.setAppElement;
export default WrappedGui;

import React from 'react';
import {FormattedMessage} from 'react-intl';

/* lch1721 */
// test

//tutorial1
import tutorial1p from './tutorial1/1_preview.gif';





/* end */

export default {
    /* lch1721 */
    'Line' : {
        name : '#001 - How to arduino for newbie(absolute beginners)',
        img: tutorial1p,
        steps : [
                    {
                        video: 'PO-eUs4c8b0'
                    }
            ]
        ,
        urlId: 'Line'
    },
    
    'Horizontal Bar' : {
        name : '#001 - How to arduino for newbie(absolute beginners)',
        img: tutorial1p,
        steps : [
                    {
                        video: 'PO-eUs4c8b0'
                    }
            ]
        ,
        urlId: 'Line'
    },
    'Donut' : {
        name : '#001 - How to arduino for newbie(absolute beginners)',
        img: tutorial1p,
        steps : [
                    {
                        video: 'PO-eUs4c8b0'
                    }
            ]
        ,
        urlId: 'Line'
    },
    'Pie' : {
        name : '#001 - How to arduino for newbie(absolute beginners)',
        img: tutorial1p,
        steps : [
                    {
                        video: 'PO-eUs4c8b0'
                    }
            ]
        ,
        urlId: 'Line'
    },
    'Bar' : {
        name : '#001 - How to arduino for newbie(absolute beginners)',
        img: tutorial1p,
        steps : [
                    {
                        video: 'PO-eUs4c8b0'
                    }
            ]
        ,
        urlId: 'Bar'
    },
    'video1' : {
        name : '#001 - How to arduino for newbie(absolute beginners)',
        img: tutorial1p,
        steps : [
                    {
                        video: 'PO-eUs4c8b0'
                    }
            ]
        ,
        urlId: 'video1'
    },
    'video2' : {
        name : '#002 - How to Arduino programming for newbie(absolute beginners)',
        img: tutorial1p,
        steps : [
                    {
                        video: 'e4NIurWVwIs'
                    },
                    {
                        deckIds: [
                            'video3',
                            'video4'
                        ]
                    }
            ]
        ,
        urlId: 'video2'
    },
    'video3' : {
        name : '#102 - Arduino joystick basic tutorial',
        img: tutorial1p,
        steps : [
                    {
                        video: '5aYE3X2p_Mc'
                    },
                    {
                        deckIds: [
                            'video4',
                            'video5'
                        ]
                    }
            ]
        ,
        urlId: 'video3'
    },
    'video4' : {
        name : '#103 - Arduino potentiometer basic tutorial',
        img: tutorial1p,
        steps : [
                    {
                        video: 'Hsavf7k5tEE'
                    },
                    {
                        deckIds: [
                            'video5',
                            'video6'
                        ]
                    }
            ]
        ,
        urlId: 'video4'
    },
    'video5' : {
        name : '#104 - Arduino servo motor basic tutorial',
        img: tutorial1p,
        steps : [
                    {
                        video: 'e66a0wRfCeA'
                    },
                    {
                        deckIds: [
                            'video6'
                        ]
                    }
            ]
        ,
        urlId: 'video5'
    },
    'video6' : {
        name : '#201 - Arduino Servo motor control with joystick',
        img: tutorial1p,
        steps : [
                    {
                        video: 'TsbyOM7SuB8'
                    },
                    {
                        deckIds: [
                            'tutorial1'
                        ]
                    }
            ]
        ,
        urlId: 'video6'
    }
    /* end */

};

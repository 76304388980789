import OpcodeLabels from './opcode-labels.js';

const isUndefined = a => typeof a === 'undefined';

/**
 * Convert monitors from VM format to what the GUI needs to render.
 * - Convert opcode to a label and a category
 * @param {string} block.id - The id of the monitor block
 * @param {string} block.spriteName - Present only if the monitor applies only to the sprite
 *     with given target ID. The name of the target sprite when the monitor was created
 * @param {string} block.opcode - The opcode of the monitor
 * @param {object} block.params - Extra params to the monitor block
 * @param {string|number|Array} block.value - The monitor value
 * @param {VirtualMachine} block.vm - the VM instance which owns the block
 * @return {object} The adapted monitor with label and category
 */
export default function ({id, spriteName, opcode, params, value, vm}) {
    // Extension monitors get their labels from the Runtime through `getLabelForOpcode`.
    // Other monitors' labels are hard-coded in `OpcodeLabels`.
    let {label, category, labelFn} = (vm && vm.runtime.getLabelForOpcode(opcode)) || OpcodeLabels.getLabel(opcode);

    // Use labelFn if provided for dynamic labelling (e.g. variables)
    if (!isUndefined(labelFn)) label = labelFn(params);

    // Append sprite name for sprite-specific monitors
    if (spriteName) {
        label = `${spriteName}: ${label}`;
    }
    
    // If value is a number, round it to six decimal places
    if (typeof value === 'number') {
        value = Number(value.toFixed(6));
    }

    // Turn the value to a string, for handle boolean values
    if (typeof value === 'boolean') {
        value = value.toString();
    }
    var stringConstructor = "test".constructor;
    var arrayConstructor = [].constructor;
    var objectConstructor = {}.constructor;


    // Lists can contain booleans, which should also be turned to strings
    if (Array.isArray(value)) {
        
        value = value.map(function(num) {
            if (num === undefined) {
                return "[undefined]";
            }
            else if (typeof num === 'number') {
                return num.toString();
            }
            else if (typeof value === 'boolean') {
                return num.toString();
            }
            else if (value.constructor === stringConstructor) {
                return num.toString();
            }
            else if (value.constructor === arrayConstructor) {
                return "[Object]";
            } else if (typeof value === 'function') {
                return "[Object]";

            } else if (value.constructor === objectConstructor) {
                return JSON.stringify(value);
            } else {
                return num.toString();
            }
        });
    } else {
        if (value === undefined) {
            
            value = "[undefined]";
        }
        else if (typeof value === 'number') {
            
        }
        else if (typeof value === 'boolean') {
            
        }
        else if (value.constructor === stringConstructor) {
            
        }
        else if (value.constructor === arrayConstructor) {
            value= "[Object]";
        }
        else if (typeof value === 'function') {
            value= "[Object]";
        }
        else if (value.constructor === objectConstructor ){
            
            value= JSON.stringify(value);
        } 

    }
    return {id, label, category, value};
}

import PropTypes from 'prop-types';
import React, {Fragment} from 'react';
import {FormattedMessage} from 'react-intl';
import Draggable from 'react-draggable';

import styles from './zxing.css';

import rightArrow from './icon--next.svg';
import leftArrow from './icon--prev.svg';

import helpIcon from '../../lib/assets/icon--tutorials.svg';
import closeIcon from './icon--close.svg';

const ZXingHeader = ({onCloseCards, onShowAll, totalSteps, step}) => (
    <div className={styles.headerButtons}>
        <div
            className={styles.allButton}
            
        >
            ZXing Barcode recognition
        </div>
        {totalSteps > 1 ? (
            <div className={styles.stepsList}>
                {Array(totalSteps).fill(0)
                    .map((_, i) => (
                        <div
                            className={i === step ? styles.activeStepPip : styles.inactiveStepPip}
                            key={`pip-step-${i}`}
                        />
                    ))}
            </div>
        ) : null}
        <div
            className={styles.removeButton}
            onClick={onCloseCards}
        >
            <FormattedMessage
                defaultMessage="Close"
                description="Title for button to close how-to card"
                id="gui.cards.close"
            />
            <img
                className={styles.closeIcon}
                src={closeIcon}
            />
        </div>
    </div>
);

// Video step needs to know if the card is being dragged to cover the video
// so that the mouseup is not swallowed by the iframe.
const VideoStep = ({video, dragging,width,height,isAndroid}) => (
    <div className={isAndroid?styles.stepAndroidVideo:styles.stepVideo}>
        <div id="zxingDiv"  className={isAndroid?styles.zxingAndroidDiv:styles.zxingDiv}>
            <div id="zxing-webcam-container">
                <video id="zxing-video" width={width} height={height} ></video>
            </div>
            <div id="zxing-label-container" className={styles.tmLabelContainer}></div>
            <div id="zxing-hides" className={styles.zxingHide}>
                <div id="zxingSourceSelectPanel" className={styles.zxingHide}>
                    <label htmlFor="zxingSourceSelect">Change video source:</label>
                    <select id="zxingSourceSelect" >
                    </select>
                </div>
            </div>
        </div>

    </div>
);

VideoStep.propTypes = {
    dragging: PropTypes.bool.isRequired,
    video: PropTypes.string,
    width: PropTypes.number,
    height: PropTypes.number,
    isAndroid: PropTypes.bool
};

const ImageStep = ({title, image}) => (
    <Fragment>
        <div className={styles.stepImageContainer}>
            <input />
        </div>
    </Fragment>
);

ImageStep.propTypes = {
    image: PropTypes.string.isRequired,
    title: PropTypes.node.isRequired
};

const NextPrevButtons = ({isRtl, onNextStep, onPrevStep}) => (
    <Fragment>
        {onNextStep ? (
            <div>
                <div className={isRtl ? styles.leftCard : styles.rightCard} />
                <div
                    className={isRtl ? styles.leftButton : styles.rightButton}
                    onClick={onNextStep}
                >
                    <img
                        draggable={false}
                        src={isRtl ? leftArrow : rightArrow}
                    />
                </div>
            </div>
        ) : null}
        {onPrevStep ? (
            <div>
                <div className={isRtl ? styles.rightCard : styles.leftCard} />
                <div
                    className={isRtl ? styles.rightButton : styles.leftButton}
                    onClick={onPrevStep}
                >
                    <img
                        draggable={false}
                        src={isRtl ? rightArrow : leftArrow}
                    />
                </div>
            </div>
        ) : null}
    </Fragment>
);

NextPrevButtons.propTypes = {
    isRtl: PropTypes.bool,
    onNextStep: PropTypes.func,
    onPrevStep: PropTypes.func
};
ZXingHeader.propTypes = {
    onCloseCards: PropTypes.func.isRequired,
    onShowAll: PropTypes.func.isRequired,
    step: PropTypes.number,
    totalSteps: PropTypes.number
};


const ZXing = props => {
    const {
        activeDeckId,
        content,
        dragging,
        isRtl,
        locale,
        onActivateDeckFactory,
        onCloseCards,
        onDrag,
        onStartDrag,
        onEndDrag,
        onShowAll,
        onNextStep,
        onPrevStep,
        step,
        isAndroid,
        ...posProps
    } = props;
    let {x, y} = posProps;

    

    if (x === 0 && y === 0) {
        // initialize positions
        x = isRtl ? -292 : 292;
        // The tallest cards are about 385px high, and the default position is pinned
        // to near the bottom of the blocks palette to allow room to work above.
        const tallCardHeight = 560;
        const bottomMargin = 60; // To avoid overlapping the backpack region
        y = window.innerHeight - tallCardHeight - bottomMargin;

        if(isAndroid){
            x = window.innerWidth - 480 - 60;
            y = 0;
        }
    }

    const steps = content['video1'].steps;

    const onGetBook = function(url){
        window.open(url,'_blank');
    };

    return (
        <Draggable
            bounds="parent"
            position={{x: x, y: y}}
            onDrag={onDrag}
            onStart={onStartDrag}
            onStop={onEndDrag}
        >
            <div className={styles.cardContainer}>
                <div className={styles.card}>
                    <ZXingHeader
                        step={step}
                        totalSteps={steps.length}
                        onCloseCards={onCloseCards}
                        onShowAll={onShowAll}
                    />
                    <div className={styles.stepBody}>
                        {steps[step].video ? (
                                <VideoStep
                                    dragging={dragging}
                                    video={null}
                                    isAndroid={isAndroid}
                                    width={isAndroid?480:720}
                                    height={isAndroid?320:560}
                                />
                            ) : (
                                <ImageStep
                                        image={steps[step].image}
                                        title={steps[step].title}
                                    />
                            )}
                        {steps[step].trackingPixel && steps[step].trackingPixel}
                    </div>
                    <NextPrevButtons
                        isRtl={isRtl}
                        onNextStep={step < steps.length - 1 ? onNextStep : null}
                        onPrevStep={step > 0 ? onPrevStep : null}
                    />
                </div>
            </div>
        </Draggable>
    );
};

ZXing.propTypes = {
    activeDeckId: PropTypes.string.isRequired,
    content: PropTypes.shape({
        id: PropTypes.shape({
            name: PropTypes.node.isRequired,
            img: PropTypes.string.isRequired,
            steps: PropTypes.arrayOf(PropTypes.shape({
                title: PropTypes.node,
                image: PropTypes.string,
                video: PropTypes.string,
                deckIds: PropTypes.arrayOf(PropTypes.string)
            }))
        })
    }),
    dragging: PropTypes.bool.isRequired,
    isRtl: PropTypes.bool.isRequired,
    locale: PropTypes.string.isRequired,
    onActivateDeckFactory: PropTypes.func.isRequired,
    onCloseCards: PropTypes.func.isRequired,
    onDrag: PropTypes.func,
    onEndDrag: PropTypes.func,
    onNextStep: PropTypes.func.isRequired,
    onPrevStep: PropTypes.func.isRequired,
    onShowAll: PropTypes.func,
    onStartDrag: PropTypes.func,
    step: PropTypes.number.isRequired,
    x: PropTypes.number,
    y: PropTypes.number,
    isAndroid : PropTypes.bool
};

export default ZXing;

import bindAll from 'lodash.bindall';
import PropTypes from 'prop-types';
import React from 'react';
import {injectIntl, intlShape, defineMessages} from 'react-intl';

import decksLibraryContent from '../lib/libraries/decks/index.jsx';
import tutorialTags from '../lib/libraries/tutorial-tags';
import VM from 'scratch-vm';
// import analytics from '../lib/analytics';
import {notScratchDesktop} from '../lib/isScratchDesktop';
import log from '../lib/log';
import {
    LoadingStates,
    getIsLoadingUpload,
    onLoadedProject,
    requestProjectUpload
} from '../reducers/project-state';

import LibraryComponent from '../components/library/library.jsx';

import {connect} from 'react-redux';

import {
    closeTipsLibrary
} from '../reducers/modals';

import {
    activateDeck,
    setContent
} from '../reducers/cards';

import {
    openLoadingProject,
    closeLoadingProject
} from '../reducers/modals';

const messages = defineMessages({
    tipsLibraryTitle: {
        defaultMessage: 'Choose a Tutorial',
        description: 'Heading for the help/tutorials library',
        id: 'gui.tipsLibrary.tutorials'
    }
});

class TipsAjaxLibrary extends React.PureComponent {
    constructor (props) {
        super(props);
        bindAll(this, [
            'handleItemSelect',
            'getDecks',
            'testSetState',
            'openProject',
            'resetFileInput',
            'getProjectTitleFromFilename',
            'onload'
        ]);

    }
    getDecks () { 
        const tmpSetState = this.setState.bind(this);
        const tmpState = this.state;
        const testSetState = this.testSetState.bind(this);
        fetch("/static/decks_1.4.json",{
            headers: {
              'Cache-Control': 'no-cache'
            }
          })
            .then(function(response) { 
                return response.json(); })
            .then(function(result) {
                const tmp = result;
                testSetState(tmp);
                
                
            });
    };
    openProject (projectId) { 
        const tmpThis = this;
        fetch("/static/projects/"+projectId+".sb3")
            .then(function(response) { 
                return response.blob(); })
            .then(function(result) {
                tmpThis.reader.readAsArrayBuffer(result);
            });
    };
    resetFileInput () {
        this.fileToUpload = null;
        if (this.fileInput) {
            this.fileInput.value = null;
        }
    }
    getProjectTitleFromFilename (fileInputFilename) {
        // if (!fileInputFilename) return '';
        // // only parse title with valid scratch project extensions
        // // (.sb, .sb2, and .sb3)
        // const matches = fileInputFilename.match(/^(.*)\.sb[23]?$/);
        // if (!matches) return '';
        // return matches[1].substring(0, 100); // truncate project title to max 100 chars
        return fileInputFilename.substring(0, 100);
    }
    onload () {
        if (this.reader) {
            this.props.onLoadingStarted();
            const filename = this.fileToUpload ;
            this.props.vm.loadProject(this.reader.result)
                .then(() => {
                    /* analytics.event({
                        category: 'project',
                        action: 'Import Project File',
                        nonInteraction: true
                    }); */
                    // Remove the hash if any (without triggering a hash change event or a reload)
                    history.replaceState({}, document.title, '.');
                    this.props.onLoadingFinished(this.props.loadingState, true);
                    // Reset the file input after project is loaded
                    // This is necessary in case the user wants to reload a project
                    if (filename) {
                        const uploadedProjectTitle = this.getProjectTitleFromFilename(filename);
                        this.props.onUpdateProjectTitle(uploadedProjectTitle);
                    }
                    this.resetFileInput();
                    
                })
                .catch(error => {
                    
                    log.warn(error);
                    // alert(this.props.intl.formatMessage(messages.loadError)); // eslint-disable-line no-alert
                    this.props.onLoadingFinished(this.props.loadingState, false);
                    // Reset the file input after project is loaded
                    // This is necessary in case the user wants to reload a project
                    this.resetFileInput();
                });
        }
    }
    testSetState(param){
        this.setState({data:param});
        this.props.onSetContent(param);
    }
    componentDidMount() {
        this.getDecks();
        this.reader = new FileReader();
        this.reader.onload = this.onload;
        this.resetFileInput();
    }
    componentWillMount () {
        // this.reader = new FileReader();
        // this.reader.onload = this.onload;
        // this.resetFileInput();
    }
    componentWillUnmount () {
        // this.reader = null;
        // this.resetFileInput();
    }
    handleItemSelect (item) {
        /* analytics.event({
            category: 'library',
            action: 'Select How-to',
            label: item.id
        }); */

        /*
            Support tutorials that require specific starter projects.
            If a tutorial declares "requiredProjectId", check that the URL contains
            it. If it is not, open a new page with this tutorial and project id.

            TODO remove this at first opportunity. If this is still here after HOC2018,
                 blame Eric R. Andrew is also on record saying "this is temporary".
            UPDATE well now Paul is wrapped into this as well. Sigh...
                eventually we will find a solution that doesn't involve loading a whole project
        */
       this.props.onActivateDeck(item.id);
        if (item.requiredProjectId && (item.requiredProjectId !== this.props.projectId)) {
            //const urlParams = `/projects/${item.requiredProjectId}/editor?tutorial=${item.urlId}`;
            // const urlParams = `?project_id=${item.requiredProjectId}&tutorial=${item.urlId}`;
            // return window.open(window.location.origin + urlParams, '_blank');
            this.openProject(item.requiredProjectId);
        }

        
    }
    render () {
        
        let decksLibraryThumbnailData ;
        if(this.state && this.state.data){
            decksLibraryThumbnailData = Object.keys(this.state.data)
            .filter(id =>
                // Scratch Desktop doesn't want tutorials with `requiredProjectId`
                notScratchDesktop() || !this.state.data[id].hasOwnProperty('requiredProjectId')
            )
            .map(id => ({
                rawURL: this.state.data[id].img,
                id: id,
                name: this.state.data[id].name,
                featured: true,
                tags: this.state.data[id].tags,
                urlId: this.state.data[id].urlId,
                requiredProjectId: this.state.data[id].requiredProjectId,
                hidden: this.state.data[id].hidden || false
            }));
        } else {
            decksLibraryThumbnailData = Object.keys(decksLibraryContent)
            .filter(id =>
                // Scratch Desktop doesn't want tutorials with `requiredProjectId`
                notScratchDesktop() || !decksLibraryContent[id].hasOwnProperty('requiredProjectId')
            )
            .map(id => ({
                rawURL: decksLibraryContent[id].img,
                id: id,
                name: decksLibraryContent[id].name,
                featured: true,
                tags: decksLibraryContent[id].tags,
                urlId: decksLibraryContent[id].urlId,
                requiredProjectId: decksLibraryContent[id].requiredProjectId,
                hidden: decksLibraryContent[id].hidden || false
            }));
        }
        

        if (!this.props.visible) return null;
        return (
            <LibraryComponent
                filterable
                data={decksLibraryThumbnailData}
                id="tipsLibrary"
                tags={tutorialTags}
                title={this.props.intl.formatMessage(messages.tipsLibraryTitle)}
                visible={this.props.visible}
                onItemSelected={this.handleItemSelect}
                onRequestClose={this.props.onRequestClose}
            />
        );
    }
}

TipsAjaxLibrary.propTypes = {
    intl: intlShape.isRequired,
    onActivateDeck: PropTypes.func.isRequired,
    onRequestClose: PropTypes.func,
    projectId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    visible: PropTypes.bool,
    canSave: PropTypes.bool, 
    isLoadingUpload: PropTypes.bool,
    loadingState: PropTypes.oneOf(LoadingStates),
    onLoadingFinished: PropTypes.func,
    onLoadingStarted: PropTypes.func,
    onUpdateProjectTitle: PropTypes.func,
    requestProjectUpload: PropTypes.func,
    vm: PropTypes.shape({
        loadProject: PropTypes.func
    }),
    onSetContent : PropTypes.func
};

const mapStateToProps = state => {
    

    const loadingState = state.scratchGui.projectState.loadingState;
    return {
        isLoadingUpload: getIsLoadingUpload(loadingState),
        loadingState: loadingState,
        vm: state.scratchGui.vm,
        visible: state.scratchGui.modals.tipsLibrary,
        projectId: state.scratchGui.projectState.projectId    
    };
};

const mapDispatchToProps = (dispatch, ownProps) => ({
    onActivateDeck: id => dispatch(activateDeck(id)),
    onRequestClose: () => dispatch(closeTipsLibrary()),
    onLoadingFinished: (loadingState, success) => {
        dispatch(onLoadedProject(loadingState, ownProps.canSave, success));
        dispatch(closeLoadingProject());
        
    },
    requestProjectUpload: loadingState => dispatch(requestProjectUpload(loadingState)),
    onLoadingStarted: () => dispatch(openLoadingProject()),
    onSetContent : content => dispatch(setContent(content))
});

const mergeProps = (stateProps, dispatchProps, ownProps) => Object.assign(
    {}, stateProps, dispatchProps, ownProps
);
/*
export default injectIntl(connect(
    mapStateToProps,
    mapDispatchToProps,
    mergeProps
)(TipsAjaxLibrary));
*/

export default connect(
    mapStateToProps,
    mapDispatchToProps,
    mergeProps
)(injectIntl(TipsAjaxLibrary));

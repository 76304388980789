import {FormattedMessage} from 'react-intl';
import PropTypes from 'prop-types';
import React from 'react';

import Box from '../box/box.jsx';
import Dots from './dots.jsx';
import bluetoothIcon from './icons/bluetooth-white.svg';
import usbIcon from './icons/usb-white.png';
import styles from './connection-modal.css';
import classNames from 'classnames';
import refreshIcon from './icons/refresh.svg';

const FirmwareUpdateConnectedStep = props => (
    <Box className={styles.body}>
        <Box className={styles.activityArea}>
            <Box className={styles.centeredRow}>
                <div className={styles.peripheralActivity}>
                    <img
                        className={styles.peripheralActivityIcon}
                        src={props.peripheralImage}
                    />
                    <img
                        className={styles.bluetoothConnectedIcon}
                        src={usbIcon}
                    />
                </div>
            </Box>
        </Box>
        <Box className={styles.bottomArea}>
            <Box className={classNames(styles.bottomAreaItem, styles.instructions)}>
                <FormattedMessage
                    defaultMessage="Firmware updated. Click 'OK' and run device connect."
                    description="Message indicating that a device was connected"
                    id="gui.connection.firmware.updated"
                />
            </Box>
            {/* <Dots
                success
                className={styles.bottomAreaItem}
                total={3}
            /> */}
            <button
                className={classNames(styles.bottomAreaItem, styles.connectionButton)}
                onClick={props.onScanning}
            >
                <FormattedMessage
                    defaultMessage="OK"
                    description="Button in prompt for starting a search"
                    id="gui.connection.firmware.list"
                />
                
            </button>
        </Box>
    </Box>
);

FirmwareUpdateConnectedStep.propTypes = {
    onCancel: PropTypes.func,
    onDisconnect: PropTypes.func,
    peripheralImage: PropTypes.string.isRequired
};

export default FirmwareUpdateConnectedStep;

exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "\n\n.login-dropdown_login_3A16U {\n    padding: 10px;\n    width: 200px;\n    line-height: 1.5rem;\n    white-space: normal; // override any parent, such as in gui, who sets nowrap\n    color: hsla(0, 100, 100, 1);\n    font-size: .8125rem;\n\n    .login-dropdown_button_2QxBj {\n        padding: .75em;\n    }\n\n    .login-dropdown_row_307b0 {\n        margin-bottom: 1.25rem;\n    }\n\n    .login-dropdown_input_2JblP {\n        margin-bottom: 12px;\n        // 100% minus border and padding\n        width: calc(100% - 30px);\n        height: 2.25rem;\n    }\n\n    label {\n        padding-top: 5px;\n        font-weight: bold;\n    }\n\n    .login-dropdown_right_6UFIS {\n        float: right;\n    }\n\n    .login-dropdown_spinner_1iO0G {\n        margin: 0 .8rem;\n        width: 1rem;\n        vertical-align: middle;\n    }\n\n    .login-dropdown_submit-button_3SVC4 {\n        margin-top: 5px;\n    }\n\n    a {\n        margin-top: 15px;\n        color: hsla(0, 100%, 100%, 1); \n\n        &:link,\n        &:visited,\n        &:active {\n            color: hsla(0, 100%, 100%, 1); \n        }\n\n        &:hover {\n            background-color: transparent;\n        }\n    }\n\n    .login-dropdown_error_1fRR_ {\n        border: 1px solid hsla(0, 0, 0, .2);\n        border-radius: 5px;\n        background-color: hsla(38, 100, 55, 1);\n        padding: .75em 1em;\n    }\n}\n", ""]);

// exports
exports.locals = {
	"login": "login-dropdown_login_3A16U",
	"button": "login-dropdown_button_2QxBj",
	"row": "login-dropdown_row_307b0",
	"input": "login-dropdown_input_2JblP",
	"right": "login-dropdown_right_6UFIS",
	"spinner": "login-dropdown_spinner_1iO0G",
	"submit-button": "login-dropdown_submit-button_3SVC4",
	"submitButton": "login-dropdown_submit-button_3SVC4",
	"error": "login-dropdown_error_1fRR_"
};
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

import styles from './login-form.css';

import StyledFirebaseAuth from 'react-firebaseui/StyledFirebaseAuth';
import firebase from 'firebase';

const Form = require('../forms/form.jsx');
const Inputs = require('../forms/inputs.jsx');
const Button = require('../forms/button.jsx');
const Spinner = require('../spinner/spinner.jsx');

const handleSignedInUser = (user)=> {
  this.props.onClose();
};

const uiConfig = {
    'callbacks': {
      // Called when the user has been successfully signed in.
      'signInSuccessWithAuthResult': function(authResult, redirectUrl) {
        if (authResult.user) {
          handleSignedInUser(authResult.user);
        }
        if (authResult.additionalUserInfo) {
          /* document.getElementById('is-new-user').textContent =
              authResult.additionalUserInfo.isNewUser ?
              'New User' : 'Existing User';*/
        }
        // Do not redirect.
        return false;
      }
    },
    // Opens IDP Providers sign-in flow in a popup.
    'signInFlow': 'popup',
    'signInOptions': [
      // TODO(developer): Remove the providers you don't need for your app.
          {
            provider: firebase.auth.GoogleAuthProvider.PROVIDER_ID,
            scopes: [
              'https://www.googleapis.com/auth/plus.login'
            ],
            customParameters: {
              // Forces account selection even when one account
              // is available.
              prompt: 'select_account'
            }
          },
          {
            provider: firebase.auth.EmailAuthProvider.PROVIDER_ID,
            signInMethod: 'password'
          },
          firebase.auth.GithubAuthProvider.PROVIDER_ID,
          {
            provider: firebase.auth.FacebookAuthProvider.PROVIDER_ID,
            scopes: [
              'email'
            ]
          }

    ],
    'credentialHelper': 'none'
    // ,
    // 'accountChooserEnabled':false
    // Terms of service url.
    //'tosUrl': 'https://www.google.com',
    // Privacy policy url.
    //'privacyPolicyUrl': 'https://www.google.com',
    
  };

const LoginFormComponent = function (props) {
    const {
        handleSubmit,
        ...componentProps
    } = props;
    
    return (

        <div>
        {/*<div id="firebaseui-container" className={styles.login}></div>*/}
        <StyledFirebaseAuth uiConfig={uiConfig} firebaseAuth={firebase.auth()}/>
        </div>
    );
};
LoginFormComponent.propTypes = {
    handleSubmit : PropTypes.func,
    onClose: PropTypes.func
};


export {
    LoginFormComponent as default
};
